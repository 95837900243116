import React from "react";
import { graphql, Link } from "gatsby";
import Slider from "react-slick";

import { Layout } from "../components/index";
import AlexPic from "../../static/images/alexander.jpeg";
import RohitPic from "../../static/images/rohit_profile.jpeg";
import RameshPic from "../../static/images/ramesh_rao.jpg";
import SlavkaPic from "../../static/images/Slavka-Dzambova.jpeg";
import VladimirPic from "../../static/images/vladimir.jpg";
//import DarshanPic from "../../static/images/darshan_kumar.jpg";

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`;

export default class About extends React.Component {
  state = {
    maxHeight: 0,
  };
  componentDidMount() {
    setTimeout(() => {
      let cards = document.getElementsByClassName("team-member");
      cards = [...cards];
      let maxHeight = 0;
      cards.forEach((element) => {
        if (element.offsetHeight > maxHeight) {
          maxHeight = element.clientHeight;
        }
      });
      this.setState({ maxHeight: maxHeight });
    }, 300);
  }
  settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  render() {
    return (
      <Layout {...this.props}>
        <div className="outer">
          <div className="inner-medium">
            <div className="post post-full">
              <header className="post-header">
                <h1 className="post-title" style={{ marginBottom: ".6em" }}>
                  About Us
                </h1>
                <div className="block-copy" style={{ textAlign: "left" }}>
                  <p>
                    KERN.ADLER Software is IT Service company which is an
                    European & Asia Centre of Excellence. We design and build
                    software products for clients searching for reliable
                    software outsourcing at reasonable costs. Taking the best
                    out of the European management standards and the Asian
                    talents – highly skilled and experienced developers, project
                    managers, scrum masters – we offer quality services at
                    competitive rates. Unlike most offshore software companies,
                    our management headquarters are in the heart of Europe: in
                    Belgium and Slovakia. We also have branches in India and
                    USA.
                    <br />
                    <br />
                    We work with experienced professionals in all major software
                    development areas: Full-stack development, backend and
                    frontend development, mobile and Web applications, UI/UX,
                    security, Big data, or blockchain technologies. Within these
                    areas, we provide a full range of outsourced service
                    solutions: End-to-end software development or
                    Pay-Per-Project model, dedicated teams, hybrid teams with
                    technical expertise.
                  </p>
                </div>
              </header>
            </div>
            {/* <center>
              <div className="about-map">
                <img src={mapImage} alt="About Map" style={{ width: "100%" }} />
              </div>
            </center> */}
          </div>
          <hr />
          {/* <div style={{ margin: "0 0 60px 0" }}>
            <TeamCarousel />
          </div> */}
          <section
            id="about-us"
            className={"block reviews-block outer"}
            style={{ paddingTop: 0 }}
          >
            <div className="inner">
              {/* <div className="grid"> */}
              <Slider {...this.settings}>
                <blockquote key={1} className="cell review full-width">
                  <div className="card">
                    <p
                      className="review-text team-member"
                      style={{ minHeight: this.state.maxHeight + 15 }}
                    >
                      “KERN.ADLER Software is connecting management and
                      communication standards of the European industry with the
                      selection of the most skilled software development
                      resources from India to provide our clients best service
                      experience at competitive rates.”
                    </p>
                    <footer className="review-footer">
                      <img
                        className="review-avatar"
                        src={AlexPic}
                        alt="Alexander Kernasevic"
                      />
                      {/* <Link
                        to={"https://www.linkedin.com/in/alexanderkernasevic/"}
                        target="_blank"
                      > */}
                        <cite className="review-author">
                          Alexander Kernasevic
                          <span className="client-role">CEO</span>
                        </cite>
                      {/* </Link> */}
                    </footer>
                  </div>
                </blockquote>
                <blockquote key={2} className="cell review full-width">
                  <div className="card">
                    <p
                      className="review-text team-member"
                      style={{ minHeight: this.state.maxHeight + 15 }}
                    >
                      “Digital era gives us the opportunity to connect the best
                      of global markets. There are big challenges in making our
                      civilisation sustainable. The software industry will
                      remain a major player in this endeavor and we at
                      KERN.ADLER Software are ready to contribute.”
                    </p>
                    <footer className="review-footer">
                      <img
                        className="review-avatar"
                        src={RohitPic}
                        alt="Rohit Ramadas"
                      />
                      {/* <Link
                        to={
                          "https://www.linkedin.com/in/rohith-ramadas-245b4b98/"
                        }
                        target="_blank"
                      > */}
                        <cite className="review-author">
                          Rohith Ramadas
                          <span className="client-role">COO</span>
                        </cite>
                      {/* </Link> */}
                    </footer>
                  </div>
                </blockquote>
                <blockquote key={3} className="cell review full-width">
                  <div className="card">
                    <p
                      className="review-text team-member"
                      style={{ minHeight: this.state.maxHeight + 15 }}
                    >
                      “We are passionate to help companies and organizations
                      through their digital transformation and technology
                      implementation. Constant learning and improvement of our
                      knowledge enables us to build software products ready to
                      disrupt the markets.”
                    </p>
                    <footer className="review-footer">
                      <img
                        className="review-avatar"
                        src={RameshPic}
                        alt="Ramesh Rao"
                      />
                      {/* <Link
                        to={"https://www.linkedin.com/in/ramesh-cnrao/"}
                        target="_blank"
                      > */}
                        <cite className="review-author">
                          Ramesh C. N. Rao
                          <span className="client-role">CTO</span>
                        </cite>
                      {/* </Link> */}
                    </footer>
                  </div>
                </blockquote>
                <blockquote key={4} className="cell review full-width">
                  <div className="card">
                    <p
                      className="review-text team-member"
                      style={{ minHeight: this.state.maxHeight + 15 }}
                    >
                      “Business development means for us building quality
                      relationships with our clients. Communication, reliability
                      and flexibility are our core values for building
                      successfully a long term business”
                    </p>
                    <footer className="review-footer">
                      <img
                        className="review-avatar"
                        src={SlavkaPic}
                        alt="Slavka Dzambova"
                      />
                      {/* <Link
                        to={"https://www.linkedin.com/in/slavka-dzambova/"}
                        target="_blank"
                      > */}
                        <cite className="review-author">
                          Slavka Dzambova
                          <span className="client-role">
                            Business development
                          </span>
                        </cite>
                      {/* </Link> */}
                    </footer>
                  </div>
                </blockquote>
                <blockquote key={5} className="cell review full-width">
                  <div className="card">
                    <p
                      className="review-text team-member"
                      style={{ minHeight: this.state.maxHeight + 15 }}
                    >
                      “Innovative mindset, agile project management and our
                      passion for continuous improvement are the key factors in
                      delivering quality software for KERN.ADLER Software
                      clients. We aim to play an irreplaceable role in your
                      business success”
                    </p>
                    <footer className="review-footer">
                      <img
                        className="review-avatar"
                        src={VladimirPic}
                        alt="Vladimir janov profile"
                      />
                      {/* <Link to={"#"} target="_blank"> */}
                        <cite className="review-author">
                          Vladimir Janov
                          <span className="client-role">Project Manager</span>
                        </cite>
                      {/* </Link> */}
                    </footer>
                  </div>
                </blockquote>
              </Slider>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}
